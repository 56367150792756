<template>
    <div class="menu">
        <div style="position: fixed;top:0; left:0;width: 100%;z-index:999;">
            <b-navbar toggleable="lg" type="dark" variant="info">
            <b-navbar-nav class="mx-auto">
            <b-navbar-brand href="#">                  
              <b-nav-form >
                <b-input-group size="sm" >
                  <router-link  to="/">
                    <b-icon  variant="light" font-scale="1.3" :icon="title.logo"></b-icon>
                    <span style="font-size: 12px;color: azure;" v-text="'(回首頁)'"></span>
                    </router-link> &ensp;        
                  <span v-text="title.title"></span> 
                  <span style="width:30px"></span>    
                </b-input-group>  
              </b-nav-form>                             
            </b-navbar-brand>                   
            </b-navbar-nav>
            </b-navbar>

        </div>
        <div style="padding:25px;"></div>         
    </div>
</template>

<script>

export default {
  name: 'Querymenubar',
  components:{
  },
  props: {
    msg: {
        type:String
    },
    title:{
        type:Object
    },  
    data1:{
        type:String
    }  
  },
  data() {
    return {
      show: null,
      queryval:this.data1,
      collapse:{ show: false },   
    };
  },  
  methods: {         
  },
  mounted(){
  },  
    destroyed() {

  },
  created() {

  }  
}
</script>
<style scoped>

</style>            