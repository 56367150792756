<template>
  <div id="itemlist" style="padding:4px;left:0;margin-top: -5px;width: 100%;z-index:10;background-color:#e1e7e8;max-height: 80px;">
                <div class="input-group">
                    <span class="input-group-text">縣市</span>
                    <select
                      v-model="twcity"
                      @change="oncity()"
                      class="form-select"
                      aria-label="First name"
                    >
                    <option v-for="list in mytw" :value="list.city" :key="list.id">{{list.city}}</option>
                    </select>     
                    <span class="input-group-text">鄉鎮</span>
                    <select
                      v-model="twvillage"  
                      @change="onvillage()"
                      class="form-select"
                      aria-label="First name"
                    >
                      <option
                        v-for="list in twvillages"
                        :value="list.village"
                        :key="list.village"
                      >{{list.village}}</option>
                    </select> 
                    <div :style="{ 'margin-left': sizes>=720? '0.35em':'0.1em' }" 
                    style="margin-left: 0.35em;">                                
                    <b-form-datepicker
                      id="date-1"                  
                      v-model="sdate"
                      :style="{ 'width': sizes>=720? '260px':'220px' }"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                       @input="get_data()"
                    ></b-form-datepicker>
                    </div>
                </div>                       
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment/moment";
import TWdata from "../assets/json/twdata.json";
import TW from "../assets/json/tw.json";
export default {
  name: 'Addresslistsimple',
  props: {
    msg: String,
    item: Array,
    winsize: Object,
    sizes: Number,
  },
  data() {
    return {
      myJson: TWdata,
      mytw: TW,
      address:'',
      twcity: "",
      twvillage: "",
      twvillages: [],
      sdate:null,    
      window: {
            width: 0,
            height: 0
        }   
    }
  }, 
  computed: {
    ...mapState("account", ["status", "user"]),
  },  
  methods: {
    fdateformat(val){
        return moment(val.sdate).format("YYYY-MM-DD HH:mm") +'~'+ moment(val.edate).format("MM-DD HH:mm") ; 
    },
    catImg(imgs) {
            return require(`../assets/img/${imgs}`);
    }, 
    getxt(val){
        return val.substring(1, 32)
    },  
    groupBy(array, groups, valueKey) {
      var map = new Map();
      groups = [].concat(groups);
      return array.reduce((r, o) => {
        groups.reduce((m, k, i, { length }) => {
          var child;
          if (m.has(o[k])) return m.get(o[k]);
          if (i + 1 === length) {
            child = Object.assign(...groups.map(k => ({ [k]: o[k] })), {
              [valueKey]: 0
            });
            r.push(child);
          } else {
            child = new Map();
          }
          m.set(o[k], child);
          return child;
        }, map)[valueKey] += +o[valueKey];
        return r;
      }, []);
    },

    getaddress(){
      let city = this.twcity==undefined ? '':this.twcity
      let village = this.twvillage==undefined ? '':this.twvillage
      this.address = city +''+ village 
      this.$emit("toaddress",this.address);
    },
    oncity() {
      this.twvillages = this.groupBy(
        this.myJson.filter(el => {
          return el.city === this.twcity;
        }),
        "village"
      );    
      this.twvillage='' 
      this.getaddress()
      //groupBy(data, 'Phase', 'Value')
    },

    onvillage() {
      this.getaddress()
    }, 
    
    get_data(){
      this.$emit("get_data",this.sdate);
    }
  },   
  
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#itemlist {
        margin:0px auto;
        padding:0px;
        max-width: 1024px;    
 }
 .form-select {
   max-width: 130px;
 }

</style>
