<template>
  <div id="itemlist"> 
    <div style="left:0;margin-top: -5px;width: 100%;z-index:10;background-color:#c9e0e4;max-height: 80px;">
          <div :style="{ 'margin': '0 auto', 'width' : sizes>=720? '92%': '355px','z-index':10,'max-height': '80px' }">
            <VueSlickCarousel
                class="btn-group"
                ref="c2"            
                :asNavFor="$refs.c1"
                :slidesToShow="sizes>=720? 4:2"
                :focusOnSelect="false">
                <div class="button"  v-for="data in myJson" :key="data.value">                                                                         
                    <div @click="query(data)">
                    <span>  
                    <a-avatar :size="24" :src="catImg(data.url)"  /> {{data.title}}
                    </span>
                    </div>                           
                </div>                                                        
            </VueSlickCarousel>   
          </div>    
    </div>                         
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment/moment";
import MY_JSON from '../assets/json/optiondataindex.json'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'Types',
  components: {
    VueSlickCarousel,
  },  
  props: {
    msg: String,
    item: Array,
    winsize: Object,
    sizes: Number,
  },
  data() {
    return {
      myJson: MY_JSON,
    }
  }, 
  computed: {
    ...mapState("account", ["status", "user"]),
  },  
  methods: {
    fdateformat(val){
        return moment(val.sdate).format("YYYY-MM-DD HH:mm") +'~'+ moment(val.edate).format("MM-DD HH:mm") ; 
    },
    catImg(imgs) {
            return require(`../assets/img/${imgs}`);
    }, 
    getxt(val){
        return val.substring(1, 32)
    }, 
    query(val){
        this.$emit("query",val); 
    }
       
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#itemlist {
        margin:0px auto;
        padding:0px;
        max-width: 1024px;    
 }
 .form-select {
   max-width: 130px;
 }

 .my-buttons .active {
    color: #fff !important;
}
.btn-group .button {
  /*background-color: #4CAF50;  Green */
  border: none;
  color: rgb(5, 59, 80);
  padding: 15px 0px;
  text-align: center;
  font-weight:bold;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  float: left;
}

.btn-group .button:hover {
  background-color: #c5d6c5;
}

</style>
