<template>
  <div class="query" > 
    <a-spin :spinning="iconLoading"> 
    <Evenmenubar :title="title" :data1="uId" /> 
    <div class="d-flex flex-column min-vh-100" style="background: linear-gradient(-45deg, #ffffff, #d2edf8, #ffffff);    ">
        <div class="mt-2" style="margin:0px auto;max-width: 1024px;"  >
                <b-input-group prepend="" style="margin-top: 0.3rem;" >
                  <b-form-input autocomplete="off" @keyup.enter="handleEnterKey"  type="search" v-model="uId" 
                    :style="{ maxWidth: '1024px', borderTopLeftRadius:  '13px',borderBottomLeftRadius:'13px'}"
                    placeholder="請輸入關鍵字或行政區域">
                  </b-form-input>
                  <b-input-group-append>
                    <b-button-group>
                      <b-button @click="clearable()" v-if="uId.length>0"  variant="link"><b-icon icon="x-circle" /></b-button>
                      <b-button @click="dQueryMyItem(1)" :disabled="uId.length==0" style="width: 3rem;margin-left: -0.1rem;" variant="success"><b-icon icon="play-circle" /></b-button>
                      <b-button @click="dQuery()" style="width: 3rem;margin-left: -0.5rem;" variant="warning"><b-icon icon="zoom-in" /></b-button>
                    </b-button-group>
                  </b-input-group-append>
                </b-input-group>                      
            <Actitemlist class="contantdiv" :item="list" kinds="inside" show="0" :winsize="windowsize" />
            <a-button v-if="list.length>0"  style="margin: 20px;" :loading="iconLoading"  @click="getmore()" type="primary" shape="round" size="large" >
              <b-icon v-show="!iconLoading" style="margin-right: 0.3rem;" scale="1.1" icon="cloud-download" variant="light" />
              取得更多資料
            </a-button>
            <a-result v-if="!rShow"  title="無相關資料, 請確認查詢條件, 或活該活動已經結束了, 謝謝">
              <template #extra>
              </template>
            </a-result>           
        </div>
    </div>    
    <b-modal ref="my-query" size="lg" id="modal-1" title="條件查詢" @hide="onHide" centered hide-footer>
          <div style="padding:0.5rem;margin-bottom: -1rem;margin-top: -1.5rem;">                       
           <span v-text="'區域條件 : '"></span><br/>
           <div style="padding:0.2rem"></div>
           <Addresscom @toaddress="toaddress" @get_data="get_data" :sizes="this.windowsize.width"   />
          </div>
          <div style="padding:0.5rem;">
            <span v-text="'活動類別 : '" ></span>
            <div style="padding:0.2rem"></div>
            <Types @query="query" :sizes="this.windowsize.width" />
          </div>              
            <div style="padding:0.2rem;text-align:left;margin:0px auto;max-width: 1024px; ">                     
            <a-breadcrumb style="font-size: 16px;font-weight: bold;margin-left: 0.2rem;" separator="">
                <a-breadcrumb-item >
                查詢條件 :  
                </a-breadcrumb-item>
                <a-breadcrumb-item >
                ( 地區 : {{raddress}}
                </a-breadcrumb-item>
                <a-breadcrumb-separator />
                <a-breadcrumb-item>
                分類 : {{reObj}}
                </a-breadcrumb-item>
                <a-breadcrumb-separator />       
                <a-breadcrumb-item>
                活動時間 : {{showdate(sdate)}})
                </a-breadcrumb-item>                         
            </a-breadcrumb> 
            </div>     
            <div style="padding:0.5rem;">
              <b-button block size="lg" @click="dQueryMyItem(2)" style="padding:0.5rem;margin-left: -0.1rem;" :disabled="sdate==null&& reObj==null&& raddress==null" variant="primary">
                查詢 <b-icon icon="zoom-in" />
              </b-button>
            </div>                 
    </b-modal>    
    <div class="wrapper flex-grow-1"></div>      
    <Efootbar /> 
  </a-spin>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import Evenmenubar from '@/components/Querymenubar.vue'
import Efootbar from '@/components/Efootbar.vue'
import Actitemlist from '@/components/Actitemlist.vue' 
import Addresscom from "../components/Addresslistsimple.vue"
import Types from "../components/Types.vue"
import { actService } from '../_services'

const queryData = obj => {
  return actService.GetactItemsByOrg(obj) 
};  

export default {
  name: 'Querys',
  components: {
    Evenmenubar,
    Efootbar,
    Actitemlist, 
    Addresscom,   
    Types,
  }, 
  data() {
    return {
      kinds:1,
      showTop: false,  
      rShow: true,       
      title:{title:'活動查詢',logo:"house-fill"}, 
      iconLoading:false,  
      uId:'',
      raddress:null,
      reObj:null,
      sdate:null,
      objs: {},      
      list:[],
      page: 1,
      classtheme:null,
      queryState2: {},         
      windowsize: {
            width: 0,
            height: 0
        } 
    };
  },  
  mounted(){
    /*this.uId = this.$route.params.id
    if(this.uId!==null && this.uId!=='' ){        
        this.infiniteHandler()
    }*/
  },
  methods: {
    clearable(){
      this.uId=''
      if(this.list.length>0){
        this.iconLoading=true                
        setTimeout(() => {
          this.list=[]             
          this.iconLoading=false     
        }, 650);                
      }
    },
    handleEnterKey() {
      if(this.uId.length>0){
        this.dQueryMyItem(1)
      }
    },

    dQueryMyItem(val){
      this.list=[]
      this.page = 1
      this.kinds= val
      this.infiniteHandler() 
      if(val==2){
        this.$refs['my-query'].hide()
      } 
    },
    getmore(){
      this.infiniteHandler()
    },
    query(val){
      this.reObj = val.title
      this.classtheme = val.value==''?'': val.value
    },
    dQuery(){
      this.raddress=null
      this.classtheme=null                      
      this.sdate=null  
      this.reObj=null 
      this.$refs['my-query'].show()
    },
    toaddress(val){
      this.raddress = val==''?'': val
    },  
    get_data(val)   {
        var my_date = new Date();
        var first_date = new Date(my_date.getFullYear(), my_date.getMonth()-1, 1);
        this.sdate = val==''?first_date: val
        this.sdate = this.fdateformat(this.sdate)
    },     
    fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
          this.objs = data;
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm");
    },

    showdate(val){
        if(val!==null){
            return moment(val).format("YYYY-MM-DD");
        }
        return ' '
    },

    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      },
    handleResize() {
            this.windowsize.width = window.innerWidth;
            this.windowsize.height = window.innerHeight;
    },
    infiniteHandler() {  
        this.iconLoading = true   
        this.rShow = true
        var my_date = new Date();
        var first_date = new Date(my_date.getFullYear(), my_date.getMonth()-1, 1);        
        let params = {
            page: this.page,
            size: 3,
            title:this.uId=='' || this.uId==null ?null:this.uId,
            address:this.raddress=='' || this.raddress==null ?null:this.raddress,
            type: this.classtheme=='' || this.classtheme==null ?null:this.classtheme,                       
            date: this.sdate!=null? this.sdate:this.fdateformat(first_date),
            states:'Y',
            kinds:this.kinds
        }
        queryData({
          ...params,
        }).then(( { data }) => {
            let obj = data.data
            if (obj!=null) {
                this.page += 1;                
                setTimeout(() => {
                  this.list.push(...obj);
                  this.iconLoading=false     
                }, 650);

            }else{
              this.rShow = false
              this.iconLoading=false
            }
        });
    },    
    
  },
  destroyed() {
        window.removeEventListener('resize', this.handleResize);
  },  
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); 
    const savedQueryState = JSON.parse(window.sessionStorage.getItem('queryState2'));
    if (savedQueryState) {
      this.queryState2 = savedQueryState;
      this.page=this.queryState2.page
      this.uId=this.queryState2.title
      this.raddress=this.queryState2.address 
      this.classtheme=this.queryState2.classtheme
      this.kinds=this.queryState2.kinds
      this.list=this.queryState2.list
      window.sessionStorage.removeItem('queryState2');
    }       

  },
  
  beforeRouteLeave(to, from, next) {
    this.queryState2={
        page: this.page,
        title:this.uId,
        address:this.raddress,
        classtheme: this.classtheme,                       
        kinds:this.kinds,
        list:this.list,
    }
    window.sessionStorage.setItem('queryState2', JSON.stringify(this.queryState2));
    next();
  },    
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.sidenav {
  width: 230px;
  position: fixed;
  z-index: 1;
  top: 130px;
  right: 0;
  overflow-x: hidden;
  padding: 0;
}
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #2196F3;
  display: block;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 60px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}
.ant-breadcrumb{
  color: #434242;
}
</style>

